/* eslint-disable camelcase  */

import { RouteLocationNormalized } from 'vue-router';

interface PaginationLinks {
    first: string;
    last: string;
    prev: string;
    next: string;
}
interface PaginationMetaLinks {
    url: string | null;
    label: string;
    active: boolean;
}
export interface PaginationMeta {
    current_page: number;
    from: number;
    last_page: number;
    links: PaginationMetaLinks[];
    path: string;
    per_page: number;
    to: number;
    total: number;
}
export interface PaginatedResponse {
    data: any[];
    links: PaginationLinks;
    meta: PaginationMeta;
}

export interface ObjectResponse {
    data?: any;
    meta?: any;
    status?: any;
    errors?: any;
    message?: string;
}

export enum OrderType {
    Dedicated = 'dedicated',
    Rent = 'rent',
    Colocation = 'colocation',
    Service = 'service',
    Cloudserver = 'cloudserver',
    Ip = 'ip',
    S3 = 's3',
    Domain = 'domain_robot',
}

export enum OrderStatus {
    Draft = 'draft',
    Completed = 'completed',
    Approved = 'approved',
    Due = 'due',
    Active = 'active',
    Canceled = 'canceled',
    Terminated = 'terminated',
    Rejected = 'rejected',
}

export enum TableCellType {
    Normal = 'normal',
    Actions = 'actions',
}

export enum PaginationLinkType {
    Normal = 'normal',
    Disabled = 'disabled',
    Current = 'current',
}

export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}
export interface Attribute {
    name: string;
    label?: string;
    isSortable: boolean;
    isSearchable: boolean;
    position: number;
    columnClass?: string;
}

export enum TooltipPosition {
    Left = 'left',
    Right = 'right',
}

export interface DescriptionListItem {
    title: string;
    data: any;
}

export enum Functional {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}

export interface Address {
    id: number;
    street: string;
    number?: string;
    additional?: string;
    zip?: string;
    city: string;
    country: Country;
}
export interface BankAccount {
    bank_name: string;
    iban: string;
    bic: string;
}
export interface Contact {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    is_authorized: boolean;
    has_account: boolean;
    is_active: boolean;
    title?: string | null;
    gender?: string | null;
    phone?: string | null;
    mobile?: string | null;
}

export enum PaymentType {
    BankTransfer = 'bank_transfer',
    DirectDebit = 'direct_debit',
    Paypal = 'paypal',
}

export interface Customer {
    number: number;
    name: string;
    vat_id: string;
    payment_type: PaymentType | string;
    payment_due_days: number;
    receipt_mail_address: string;
    address?: Address;
    bankAccounts: BankAccount[];
    contacts: Contact[];
}

export enum InvoiceStatus {
    Open = 'open',
    Paid = 'paid',
}

export enum InvoiceType {
    Invoice = 'invoice',
    CreditNote = 'credit_note',
}

export enum Theme {
    None = 'none',
    Normal = 'normal',
    Primary = 'primary',
    Secondary = 'secondary',
    Cta = 'cta',
    Danger = 'danger',
    Disabled = 'disabled',
    Success = 'success',
    Warning = 'warning',
    Light = 'light',
    Dark = 'dark',
}

export enum FormTheme {
    Website = 'website',
    CustomerArea = 'customer_area',
}

export interface Invoice {
    number: string;
    status: InvoiceStatus | string;
    date: string | null;
    due_on: string | null;
    total_net: string | null;
    total_gross: string | null;
    type: InvoiceType;
}

export enum IconKey {
    Extern = 'extern',
}

export interface ActionItem {
    name: string;
    onClick: Function;
    iconType?: IconType;
}

export enum FileType {
    Pdf = 'application/pdf',
    Csv = 'text/csv',
}

export enum Size {
    None,
    Small,
    Normal,
    Large,
}

export enum SizeAll {
    Tiny,
    Small,
    Normal,
    Large,
    Huge,
}

export interface File {
    name: string;
    type: FileType;
    size: number;
    base64Content: string;
}

export interface TestItem {
    id: number;
    name: string;
}

export interface OptionItem {
    id: string;
    name: string;
}

export interface TableItem {
    resource: Order | Invoice | Customer | TestItem | NetworkIp;
    notifications?: string[];
}

export enum ToastType {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}
export interface Toast {
    key: string;
    title: string;
    text: string;
    type: ToastType;
    isVisible: boolean;
}

export interface Country {
    id: number;
    code: string;
    name: string;
    is_dach: boolean;
}

export enum TextFieldType {
    Text = 'text',
    Email = 'email',
    Password = 'password',
    Number = 'number',
    Search = 'search',
    Tel = 'tel',
    Url = 'url',
    Checkbox = 'checkbox',
    File = 'file',
}

export enum OrderItemCategory {
    Device = 'device',
    Ram = 'ram',
    Storage = 'storage',
    Network = 'network',
    Service = 'service',
    Domain = 'domain_robot',
    Ip = 'ip',
    S3 = 's3',
    Colocation = 'colocation',
    Power = 'power',
    Cloudserver = 'cloudserver',
    CloudserverResource = 'cloudserver_resource',
    Locker = 'locker',
    Unknown = 'unknown',
}

export enum OrderItemType {
    CloudserverItem = 'order_cloudserver_resource_item',
    ColocationItem = 'order_colocation_item',
    DeviceComponentItem = 'order_device_component_item',
    DeviceItem = 'order_device_item',
    IpItem = 'order_ip_item',
    PowerItem = 'order_power_item',
    FeedItem = 'order_feed_item',
    ServiceItem = 'order_service_item',
    TrafficItem = 'order_traffic_item',
    UplinkItem = 'order_uplink_item',
}

export enum UplinkType {
    Wan = 'wan',
    Lan = 'lan',
}

export interface OrderItemPricing {
    price_per_unit: number;
    units_included_monthly: number;
}

export interface DeviceUnit {
    name: string[];
}

export interface OrderDeviceItemExtra {
    device_usage: DeviceUsage;
    device_unit: DeviceUnit;
    cpu_name: string;
    cpu_cores: number;
    cpu_threads: number;
    cpu_frequency: number;
    rack_name: string;
}

export interface OrderColocationItemExtra {
    colocation_usage: ColocationUsage;
    rack_name: string;
    rack_room: string;
}

export interface OrderDeviceItemGraph {
    graph_from: string;
    graph_to: string;
    graph_as_svg: string;
}

export interface OrderColocationItemGraph {
    graph_from: string;
    graph_to: string;
    graph_as_svg: string;
}

export interface OrderTrafficItemGraph {
    graph_from: string;
    graph_to: string;
    graph_as_svg: string;
}

export interface FetchOrderDeviceItemGraphMeta {
    from_min: Date;
    from: Date;
    to: Date;
    to_max: Date;
}

export interface FetchOrderColocationItemGraphMeta {
    from_min: Date;
    from: Date;
    to: Date;
    to_max: Date;
}

export interface FetchOrderFeedItemPowerGraphMeta {
    from_min: Date;
    from: Date;
    to: Date;
    to_max: Date;
}

export interface FetchOrderTrafficItemMeta {
    from_min: Date;
    from: Date;
    to: Date;
    to_max: Date;
}

export interface OrderItem {
    id: number;
    category: OrderItemCategory;
    description: string;
    type: OrderItemType;
    items?: any[];
    pricing?: OrderItemPricing;
    extra_data?: OrderDeviceItemExtra | OrderColocationItemExtra;
}

export interface UpdateS3PasswordValidationError {
    password: string[];
    password_confirmation: string[];
}

export interface CreateTicketValidationError {
    topic: string[];
    subject: string[];
}

export interface uploadFileValidationError {
    file: string[];
}

export interface FetchOrderDeviceItemGraphsValidationError {
    from: string[];
    to: string[];
}

export interface FetchOrderColocationItemGraphsValidationError {
    from: string[];
    to: string[];
}

export interface FetchOrderTrafficItemGraphsValidationError {
    from: string[];
    to: string[];
}

export interface FetchPaginatedTicketsValidationError {
    from: string[];
    to: string[];
}

export interface FetchOrderFeedItemPowerGraphValidationError {
    from: string[];
    to: string[];
}

export type IndexValidationError = {
    search: string[];
};

export type FetchPaginatedOrdersValidationError = IndexValidationError;

export type FetchPaginatedInvoicesValidationError = IndexValidationError;

export type FetchPaginatedIpUsageAddressesValidationError = IndexValidationError;

export type FetchPaginatedIpUsageNetworksValidationError = IndexValidationError;

export type FetchPaginatedNetworkIpsValidationError = IndexValidationError;

export type FetchPaginatedDeviceUsagesValidationError = IndexValidationError;

export type FetchPaginatedColocationUsagesValidationError = IndexValidationError;

export interface Interval {
    interval_number: number;
    interval_unit: string;
}
export interface Order {
    number: string | object;
    status: OrderStatus | string;
    type: OrderType | string;
    completed_at: string | null;
    approved_at: string | null;
    planned_start_at: string | null;
    start_at: string | null;
    end_at: string | null;
    setup_price: number;
    monthly_price: number;
    cancelation_period: Interval;
    extension_period: Interval;
    min_order_duration: Interval;
    items: OrderItem[];
}

export enum IpType {
    IpAddress = 'ip_address',
    IpV6Network = 'ipv6_network',
    IpV4Network = 'ipv4_network',
}

export enum StepStatus {
    Complete = 'complete',
    Current = 'current',
    Incomplete = 'incomplete',
}

export interface NetworkIp {
    ip: string;
    dns_name: string;
}

export interface UpdateIpUsageNetworkValidationError {
    customer_reference?: string[];
}

export interface IpUsage {
    id: number;
    ip: string;
    order_number: string | object;
    ip_type: IpType;
    dns_name: string;
    network_ip_count: number;
    has_dns_management: boolean;
    customer_reference?: string;
}

export interface UpdateIpUsageAddressValidationError {
    dns_name?: string[];
    customer_reference?: string[];
}

export interface Cpu {
    name: string;
    cores: number;
    threads: number;
    frequency: number;
}

export interface ComponentUnitDescription {
    count: number;
    component_name: string;
}

export interface DeviceUnitComponentUnitDescription {
    component_type_name: string;
    component_unit_descriptions: ComponentUnitDescription[];
}

export interface UpdateDeviceUsageValidationError {
    order_device_item_customer_reference?: string[];
}

export interface UpdateColocationUsageValidationError {
    order_colocation_item_customer_reference?: string[];
}

export interface CreateContactValidationError {
    email: string[];
}

export interface UpdateContactValidationError {
    email: string[];
}

export interface DeviceUsage {
    id: number;
    device_name: string;
    device_unit_name: string;
    device_unit_old_name: string | null;
    device_unit_component_unit_descriptions: DeviceUnitComponentUnitDescription[];
    device_unit_netbox_item_rack_name: string;
    device_unit_netbox_item_rack_room: string;
    cpu: Cpu;
    order_number: string;
    order_device_item_id: number;
    order_device_item_customer_reference: number;
    order_traffic_item_ids: number[];
}

export interface ColocationUsage {
    id: number;
    rack_name: string;
    rack_room: string;
    order_number: string;
    order_colocation_item_customer_reference: string;
    order_colocation_item_colocation_name: string;
    order_colocation_item_order_feed_item_id: number;
    order_colocation_item_order_feed_item_current_power_consumption: number;
}

export interface CreateNetworkIpValidationError {
    ip?: string[];
    mask?: string[];
    dns_name?: string[];
}

export interface UpdateNetworkIpValidationError {
    ip?: string[];
    mask?: string[];
    dns_name?: string[];
}

export interface TabsItem {
    id: string;
    label: string;
    badge?: string | number;
}

export enum TabsItemTheme {
    Configurator = 'configurator',
    Underline = 'underline',
}

export enum BadgeSize {
    Small = 'small',
    Large = 'large',
}

export interface OrderTrafficUsage {
    graph_svg: string;
}

export interface FluxItem {
    date: string;
    value: number;
}

export interface PowerGraphDataset {
    name: string;
    fluxItems: FluxItem[];
}

export interface OrderFeedItemPowerGraph {
    datasets: PowerGraphDataset[];
    kwh: number | null;
}

export interface OrderTrafficItem {
    id: number;
    category: OrderItemCategory;
    description: string;
    type: OrderItemType;
    items?: any[];
    order_traffic_usages: OrderTrafficUsage[];
}

export interface UserPermission {
    canUseServerModule: boolean;
    canUseIpamModule: boolean;
    canUseColocationModule: boolean;
    can_see_receipt_csv: boolean;
}
export interface User {
    name: string;
    contact_id: number;
    permissions: UserPermission;
    has_two_factor_authentication: boolean;
}

export interface UpdateUserPasswordValidationError {
    password_current?: string[];
    password?: string[];
    password_confirmation?: string[];
}

export interface confirmTwoFactorAuthenticationCodeValidationError {
    code?: string[];
}

export interface confirmPasswordCodeValidationError {
    password?: string[];
}

export enum GenderType {
    Man = 'm',
    Woman = 'f',
    Divers = 'd',
}

export interface SelectOption {
    key: string;
    label: string;
}

export interface LinkTo {
    id: string;
    label: string;
    url: string;
    to?: RouteLocationNormalized;
}

export enum DisplayType {
    Table = 'table',
    Grid = 'grid',
}

export type Changelog = {
    id: string;
    locale: string;
    date: string;
    title: string;
    content: string;
};

export enum CardType {
    Normal = 'normal',
    Preview = 'Preview',
}

export enum TicketStatus {
    New = 'new',
    Open = 'open',
    Pending = 'pending',
    Hold = 'hold',
    Solved = 'solved',
    Closed = 'closed',
}

export interface Ticket {
    subject: string;
    id: number;
    created_at: string;
    updated_at: string;
    status: TicketStatus;
}

export interface TicketFile {
    id: number;
    token: string;
    url: string;
    name: string;
}

export enum TicketFetchingSourceType {
    Dashboard = 'dashboard',
    Index = 'index',
}

export enum AssortmentType {
    LatestServer = 'latest_server',
    ServerDeals = 'server_deals',
    Colocation = 'colocation',
    S3 = 's3',
    Network = 'network',
    Ip = 'ip',
    Cloud = 'cloud',
}

export enum CloudserverResourceType {
    Cpu = 'cpu',
    Ram = 'ram',
    Storage = 'storage',
}
